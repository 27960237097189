import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Logo() {
	const { t } = useTranslation('labels')
  
	return (
		<img alt={t('Logo')} src='/images/logo.svg' />
	)
}

import React from 'react'

import { Content as ContentComponent } from 'components'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'

import Cost from './Cost'
import Cover from './Cover'
import Info from './Info'
import styles from './styles.module.scss'

export default function Content() {
	useMainLayoutContext()

	return (
		<ContentComponent className={styles.root}>
			<Cover />
			<Info />
			<Cost />
		</ContentComponent>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Col, Row, Space, Text, useBreakpoint } from 'components'
import FooterForm from 'features/layouts/blocks/Footer/Form'
import ContactEmail from 'features/layouts/ui/ContactEmail'
import ContactPhone from 'features/layouts/ui/ContactPhone'
import Logo from 'features/layouts/ui/Logo'
import SoftwareInfo from 'features/layouts/ui/SoftwareInfo'

import styles from './styles.module.scss'

export default function Footer() {
	const { t } = useTranslation(['actions', 'labels'])
	const { lg } = useBreakpoint()

	return (
		<Row
			wrap={!lg}
			gutter={ESpacer.SPACER5}
			className={styles.root}
			align='middle'
		>
			<Col className={styles.col} flex={1} order={lg ? 0 : 1}>
				<Space
					isFullWidth
					direction='vertical'
					size={ESpacer.SPACER16}
					className={styles.space}
				>
					<Logo />
					<Space
						className={styles.documentationSpace}
						isFullWidth
						direction='vertical'
						size={ESpacer.SPACER16}
					>
						<Space direction='vertical'>
							<Text type='body16'>{t('Contacts')}</Text>
							<ContactPhone type='header3' bold />
							<ContactEmail />
						</Space>
						<Space direction='vertical'>
							<Text type='body16'>{t('Common information')}</Text>
							<SoftwareInfo />
						</Space>
					</Space>
				</Space>
				<Text type='body16'>{t('infogis')},&nbsp;{new Date().getFullYear()}</Text>
			</Col>
			<Col className={styles.formCol} flex={1} order={lg ? 1 : 0}>
				<FooterForm />
			</Col>
		</Row>
	)
}

import React from 'react'

import { CONTACT_PHONE } from 'common/constants/services'
import { Text } from 'components'

import { IContactPhoneProps } from './types'

export default function ContactPhone(props: IContactPhoneProps) {
	const { type = 'body16', bold } = props

	return (
		<a href={`tel:${CONTACT_PHONE}`}>
			<Text type={type} bold={bold}>{CONTACT_PHONE}</Text>
		</a>
	)
}

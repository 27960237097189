import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { getAntdLocale } from 'app/localization'
import { store } from 'app/store/store'
import THEME from 'assets/theme/antd'
import { AntStaticFunctions, App, ConfigProvider, ErrorBoundary } from 'components'
import { MainLayout } from 'features/layouts/blocks/MainLayout'
import 'assets/theme/styles/index.scss'

dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.locale('ru')

function Index() {
	const { i18n } = useTranslation()
	const locale = useMemo(() => getAntdLocale(i18n.language), [i18n])

	return (
		<Provider store={store}>
			<ConfigProvider locale={locale} theme={THEME}>
				<App>
					<BrowserRouter>
						<ErrorBoundary>
							<MainLayout />
						</ErrorBoundary>
					</BrowserRouter>
					<AntStaticFunctions />
				</App>
			</ConfigProvider>
		</Provider>
	)
}

export default Index

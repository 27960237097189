import React from 'react'

import { CONTACT_EMAIL } from 'common/constants/services'
import { Text } from 'components'


export default function ContactEmail() {
	return (
		<a href={`mailto:${CONTACT_EMAIL}`}>
			<Text type='header3' bold>{CONTACT_EMAIL}</Text>
		</a>
	)
}

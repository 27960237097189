import React from 'react'
import { useTranslation } from 'react-i18next'

import { SOFTWARE_DOCUMENTATION } from 'common/constants/services'
import { Text } from 'components'


export default function SoftwareInfo() {
	const { t } = useTranslation('labels')

	return (
		<a href={`/documents/${SOFTWARE_DOCUMENTATION}`} download>
			<Text type='header3' bold>{t('Software documentation')}</Text>
		</a>
	)
}

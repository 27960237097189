import { useContext, useEffect } from 'react'

import { MainLayoutContext } from './context'
import { IMainLayoutOptions } from './types'

export const useMainLayoutContext = (initValue?: IMainLayoutOptions) => {
	const layoutContext = useContext(MainLayoutContext)

	useEffect(() => {
		layoutContext.initialization(initValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		layoutContext,
		initValue?.className,
		initValue?.headerClassName,
		initValue?.showHeader,
		initValue?.contentClassName,
	])

	return layoutContext
}

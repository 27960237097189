import React from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { COST_ID } from 'common/constants/ancors'
import { Col, Divider, Icon, Row, Space, Text } from 'components'

import styles from './styles.module.scss'

export default function Cost() {
	const { t } = useTranslation('labels')

	return (
		<Row className={styles.cost} id={COST_ID}>
			<Col className={styles.titleCol} flex={1}>
				<Text type='body16' className={styles.title}>
					{t('Cost').toUpperCase()}
				</Text>
			</Col>
			<Col flex={1} className={styles.costRightContainer}>
				<Row
					justify='space-between'
					align='top'
					className={styles.costDescriptionRow}
					gutter={ESpacer.SPACER8}
				>
					<Col flex={1} className={styles.costDescriptionItem}>
						<Icon icon='researcher' size={80} />
						<Divider />
						<Space direction='vertical'>
							<Text className={styles.costTitle}>{t('costPerGa', { cost: 200 })}</Text>
							<Text type='body16'>{t('when surveying large forest areas')}</Text>
						</Space>
					</Col>
					<Col flex={1} className={styles.costDescriptionItem}>
						<Icon icon='researcher2' size={80} />
						<Divider />
						<Space direction='vertical'>
							<Text className={styles.costTitle}>{t('costPerGa', { cost: 1200 })}</Text>
							<Text type='body16'>{t('during the allocation and taxation of cutting areas')}</Text>
						</Space>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

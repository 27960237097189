import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { POSSIBILITIES_ID } from 'common/constants/ancors'
import { Col, Row, Space, Text, useBreakpoint } from 'components'

import styles from './styles.module.scss'

const DATA = [
	{
		img: '/images/info_cover_1.png',
		leftTitle: 'forest development planning',
		leftDescription: 'creation of an updated digital database (DB)',
		rightTitle: 'allotment of plots',
		rightDescription: 'planning, taxation and calculation of MDOL',
		inverse: false,
	},
	{
		img: '/images/info_cover_2.png',
		leftTitle: 'updating of individual sections',
		leftDescription: 'and inclusion in the forest development project',
		rightTitle: 'assessment of auction plots',
		rightDescription: 'before the auction',
		inverse: true,
	},
	{
		img: '/images/info_cover_3.png',
		leftTitle: 'assessment of tree and shrub vegetation',
		leftDescription: 'at linear facilities',
		rightTitle: 'monitoring of protected areas',
		inverse: false,
	},
	{
		img: '/images/info_cover_4.png',
		leftTitle: 'assessment of compensatory reforestation',
		leftDescription: 'by volume and growth',
		rightTitle: 'search for power line defects',
		inverse: true,
	},
]

export default function Info() {
	const { t } = useTranslation('labels')
	const { lg = true } = useBreakpoint()

	return (
		<Row className={styles.info} id={POSSIBILITIES_ID}>
			<Col className={styles.titleCol} flex={1}>
				<Text type='body16' className={styles.title}>
					{t('Possibility of using lidar taxation technology').toUpperCase()}
				</Text>
			</Col>
			<Col className={styles.infoRightContainer} flex={1}>
				{DATA.map(({ img, leftTitle, leftDescription, rightTitle, rightDescription, inverse }) => (
					<Space
						key={img}
						wrap={false}
						className={classNames(styles.infoRow, {
							[styles.inverseRow]: inverse && lg,
						})}
						size={ESpacer.SPACER6}
					>
						<Space className={styles.infoRowItemLeft} size={ESpacer.SPACER5}>
							<img src={img} width={234} height={120} />
							<Space direction='vertical' className={styles.infoRowItemLeftText}>
								<Text type='header3' bold>{t(leftTitle)}</Text>
								<Text type='body16'>{t(leftDescription)}</Text>
							</Space>
						</Space>
						<Space direction='vertical' className={styles.infoRowItemRight}>
							<Text type='header3' bold>{t(rightTitle)}</Text>
							{rightDescription && <Text type='body16'>{t(rightDescription)}</Text>}
						</Space>
					</Space>
				))}
			</Col>
		</Row>
	)
}

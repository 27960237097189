import classNames from 'classnames'
import React from 'react'

import { Typography } from '../index'

import styles from './styles.module.scss'
import { ITextProps } from './types'

export function Text(props: ITextProps) {
	const { type = 'body14', children, bold, className, typographyType, ...restProps } = props

	return (
		<Typography.Text
			{...restProps}
			type={typographyType}
			className={classNames(className, [styles[type]], {
				[styles.bold]: bold,
			})}
		>
			{children}
		</Typography.Text>
	)
}

Text.Text = Typography.Text
Text.Link = Typography.Link
Text.Paragraph = Typography.Paragraph
Text.Title = Typography.Title

export const SPACER = 8

export enum ESpacer {
  SPACER0 = 0,
  SPACER1 = SPACER / 4,
  SPACER2 = SPACER / 2,
  SPACER3 = SPACER,
  SPACER4 = SPACER * 1.5,
  SPACER5 = SPACER * 2,
  SPACER6 = SPACER * 2.5,
  SPACER7 = SPACER * 3,
  SPACER8 = SPACER * 3.5,
  SPACER9 = SPACER * 4,
  SPACER10 = SPACER * 4.5,
  SPACER11 = SPACER * 5,
  SPACER12 = SPACER * 5.5,
  SPACER13 = SPACER * 6,
  SPACER14 = SPACER * 6.5,
  SPACER15 = SPACER * 7,
  SPACER16 = SPACER * 7.5,
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FOREST_MANAGEMENT_INSTRUCTIONS } from 'common/constants/services'
import { Col, Divider, Icon, Row, Text } from 'components'

import styles from './styles.module.scss'

export default function Cover() {
	const { t } = useTranslation('labels')

	return (
		<div className={styles.cover}>
			<Row justify='space-between'>
				<Col flex={1} className={styles.logoLeftContainer}>
					<Text type='body16' className={styles.title}>{t('LIDAR TAXATION')}</Text>
				</Col>
				<Col flex={1} className={styles.logoRightContainer}>
					<span className={styles.logoLabel}>Foresight</span>
					<Text type='header1' bold className={styles.logoDescription}>{t('logoDescription')}</Text>
					<Row
						justify='space-between'
						align='top'
						className={styles.descriptionRow}
					>
						<Col className={styles.descriptionItem}>
							<Icon icon='icon_software' size={50} />
							<Divider />
							<Text type='body16'>{t('softwareDescription')}</Text>
						</Col>
						<Col className={styles.descriptionItem}>
							<Icon icon='icon_BPLA' size={50} />
							<Divider />
							<Text type='body16'>{t('bplaDescription')}</Text>
						</Col>
						<Col className={styles.descriptionItem}>
							<Icon icon='icon_ algorithms' size={50} />
							<Divider />
							<Text type='body16'>
								{t('alghorithmDescription')}
								{' '}
								<Text.Link href={`/documents/${FOREST_MANAGEMENT_INSTRUCTIONS}`} download>
									{t('complies with forest management instructions')}
								</Text.Link>
							</Text>
						</Col>
					</Row>
				</Col>
			</Row>
			<div className={styles.logo} />
		</div>
	)
}

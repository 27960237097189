import { Col, Row } from 'antd'
import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'
import { IContentProps } from './types'

export function Content({ children, isFullWidth, className, isFullHeight }: IContentProps) {
	return (
		<Row
			justify='center'
			align={isFullHeight ? 'top' : 'middle'}
			className={classNames(styles.root, className, {
				[styles.fullWidth]: isFullWidth,
				[styles.fullHeight]: isFullHeight,
			})}
		>
			<Col xs={24}>
				{children}
			</Col>
		</Row>
	)
}

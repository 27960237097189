import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'

import { EServiceTags } from 'services/types'

export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: '/',
	}),
	tagTypes: [
		EServiceTags.Unauthorized,
		EServiceTags.UnknownError,
	],
	endpoints: () => ({}),
})

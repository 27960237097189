import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ROUTES } from 'app/router/constants'
import { ESpacer } from 'assets/theme/variables/spacers'
import { COST_ID, POSSIBILITIES_ID } from 'common/constants/ancors'
import { Space, Text } from 'components'

import { IHeaderNavigationProps } from './types'

export default function Navigation(props: IHeaderNavigationProps) {
	const { t } = useTranslation('labels')
	const { direction, size = ESpacer.SPACER11, onScrollPage } = props

	return (
		<Space
			direction={direction}
			wrap={false}
			size={size}
		>
			<Link to={ROUTES.Index} onClick={() => onScrollPage(POSSIBILITIES_ID)}>
				<Text type='body16'>{t('Possibilities')}</Text>
			</Link>
			<Link to={ROUTES.Index} onClick={() => onScrollPage(COST_ID)}>
				<Text type='body16'>{t('Price')}</Text>
			</Link>
		</Space>
	)
}

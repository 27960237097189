import React from 'react'
import { useTranslation } from 'react-i18next'

import { CONSULTATION_ID } from 'common/constants/ancors'
import { Button } from 'components'

export default function ConsultationButton({ onScrollPage }: { onScrollPage(anchor: string): void }) {
	const { t } = useTranslation('actions')

	return (
		<Button type='primary' onClick={() => onScrollPage(CONSULTATION_ID)}>{t('Get consultation')}</Button>
	)
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Drawer, Space, useBreakpoint } from 'components'
import Index from 'features/layouts/ui/ConsultationButton'
import ContactPhone from 'features/layouts/ui/ContactPhone'

import { HEADER_MENU_WIDTH } from './constants'
import Navigation from './Navigation'
import styles from './styles.module.scss'
import { IHeaderMenuProps } from './types'

export default function Menu(props: IHeaderMenuProps) {
	const { t } = useTranslation('labels')
	const { open, onClose } = props
	const { xs, sm } = useBreakpoint()

	const width = useMemo(() => {
		switch (true) {
		case sm:
			return HEADER_MENU_WIDTH.SM
		case xs:
			return HEADER_MENU_WIDTH.XS
		}
	}, [xs, sm])

	const onScrollPage = (anchorId: string) => {
		onClose()

		document.getElementById(anchorId)?.scrollIntoView({
			behavior: 'smooth'
		})
	}

	return (
		<Drawer
			title={<img alt={t('Logo')} src='/images/logo.svg' />}
			open={open}
			onClose={onClose}
			className={styles.menu}
			width={width}
		>
			<Space
				direction='vertical'
				size={ESpacer.SPACER8}
			>
				<Navigation onScrollPage={onScrollPage} direction='vertical' size={ESpacer.SPACER8} />
				<ContactPhone />
				<Index onScrollPage={onScrollPage} />
			</Space>
		</Drawer>
	)
}

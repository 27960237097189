import { configureStore } from '@reduxjs/toolkit'

import { isProduction } from 'common/constants/client'
import { api } from 'services'

const REDUCERS = {
	[api.reducerPath]: api.reducer,
}

const MIDDLEWARES = [
	api.middleware
]

export const store = configureStore({
	reducer: REDUCERS,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(MIDDLEWARES),
	devTools: isProduction,
	enhancers: [],
})

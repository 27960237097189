import React from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { CONSULTATION_ID } from 'common/constants/ancors'
import { PRIVACY_POLICY } from 'common/constants/services'
import { Button, Form, FormGenerator, Space, Text, useBreakpoint } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'

import styles from './styles.module.scss'

export default function FooterForm() {
	const { t } = useTranslation(['labels', 'placeholders', 'actions'], { nsMode: 'fallback' })
	const { md = true } = useBreakpoint()

	const schema: IFormScheme[] = [
		{
			label: t('Name'),
			name: 'name',
			required: true,
			inputProps: {
				placeholder: t('Enter name'),
			},
		},
		{
			label: t('Phone'),
			name: 'phone',
			type: EFormTypes.Mask,
			required: true,
			inputProps: {
				mask: '+7(999)-999-99-99',
				placeholder: '+7 (___)-___-__-__',
				maskChar: '_',
			},
		},
		{
			label: t('Email'),
			name: 'email',
			required: true,
			inputProps: {
				placeholder: t('Enter email'),
			},
		},
		{
			label: t('Company'),
			name: 'company',
			required: true,
			inputProps: {
				placeholder: t('Enter company name'),
			},
		},
		{
			label: t('Comment'),
			name: 'comment',
			type: EFormTypes.Textarea,
			inputProps: {
				placeholder: t('Enter question'),
			},
		},
	]

	const onFinish = (values: any) => {
		console.log('Received values of form: ', values)
	}

	return (
		<div className={styles.formContainer} id={CONSULTATION_ID}>
			<Space isFullWidth direction='vertical' size={ESpacer.SPACER6}>
				<Space isFullWidth direction='vertical'>
					<Text type='header3' bold>{t('Check out our product')}</Text>
					<Text type='body16'>{t('Leave a request for detailed consultation')}</Text>
				</Space>
				<Form
					className={styles.form}
					onFinish={onFinish}
				>
					<FormGenerator scheme={schema} />
					<Space className={styles.formActions} direction={md ? 'horizontal' : 'vertical'}>
						<Button block={!md} type='primary' htmlType='submit'>{t('Send request')}</Button>
						<Text>
							{t('By clicking the button, you agree to')}
							&nbsp;
							<Text.Link href={`/documents/${PRIVACY_POLICY}`} download>{t('Privacy Policy')}</Text.Link></Text>
					</Space>
				</Form>
			</Space>
		</div>
	)
}

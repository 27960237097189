import { MenuOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from 'app/router/constants'
import { ESpacer } from 'assets/theme/variables/spacers'
import { useBooleanFlag } from 'common/hooks/boolean'
import { useIsMobile } from 'common/hooks/device'
import { Button, Col, Grid, Row, Space } from 'components'
import Index from 'features/layouts/ui/ConsultationButton'
import ContactPhone from 'features/layouts/ui/ContactPhone'
import Logo from 'features/layouts/ui/Logo'

import Menu from './Menu'
import Navigation from './Navigation'
import styles from './styles.module.scss'

export default function Header() {
	const isMobile = useIsMobile()
	const { xs } = Grid.useBreakpoint()
	const [isMenuOpened, setIsMenuOpened] = useBooleanFlag()

	const onOpenMenuCallback = useCallback(() => setIsMenuOpened(true), [setIsMenuOpened])

	const onCloseMenuCallback = useCallback(() => setIsMenuOpened(false), [setIsMenuOpened])

	const onScrollPage = (anchorId: string) => {
		onCloseMenuCallback()
		document.getElementById(anchorId)?.scrollIntoView({
			behavior: 'smooth'
		})
	}

	return (
		<>
			<Row
				wrap={false}
				className={styles.root}
				align='middle'
				justify='space-between'
			>
				<Col className={styles.logo}>
					<Link to={ROUTES.Index}>
						<Logo />
					</Link>
				</Col>
				<Col hidden={isMobile}>
					<Navigation onScrollPage={onScrollPage} />
				</Col>
				<Col>
					<Space wrap={false} size={ESpacer.SPACER8}>
						{!isMobile && (
							<ContactPhone />
						)}
						{!xs && (
							<Index onScrollPage={onScrollPage} />
						)}
						{isMobile && (
							<Button
								onClick={onOpenMenuCallback}
								type='icon'
							>
								<MenuOutlined />
							</Button>
						)}
					</Space>
				</Col>
			</Row>
			<Menu
				open={isMenuOpened}
				onClose={onCloseMenuCallback}
			/>
		</>
	)
}

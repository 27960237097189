import AntdIcon from '@ant-design/icons'
import { DatePicker, FloatButton, Grid } from 'antd'

const { useBreakpoint } = Grid
const { WeekPicker, TimePicker, MonthPicker, RangePicker, YearPicker, QuarterPicker } = DatePicker
const { BackTop } = FloatButton

export {
	Grid,
	useBreakpoint,
	WeekPicker,
	TimePicker,
	MonthPicker,
	RangePicker as AntdRangePicker,
	YearPicker,
	QuarterPicker,
	FloatButton,
	BackTop,
	AntdIcon,
}

export type { ColumnsType } from 'antd/lib/table'
export type { LinkProps } from 'antd/lib/typography/Link'
export type { UploadFile, UploadChangeParam, UploadProps, RcFile } from 'antd/lib/upload/interface'
export type { Rule } from 'rc-field-form/es/interface'
export type { FilterValue, SorterResult, TableCurrentDataSource, FilterDropdownProps, FilterConfirmProps } from 'antd/es/table/interface'
export type {
	SelectProps,
	FormInstance,
	FormListFieldData,
	TablePaginationConfig,
	MenuProps,
	InputProps as AntdInputProps,
	SpinProps as AntdSpinProps,
	ModalProps,
	FormProps,
	FormItemProps,
	BreadcrumbProps as AntdBreadcrumbProps,
	SpaceProps as AntdSpaceProps,
	RadioChangeEvent,
	PaginationProps,
	RadioGroupProps,
	RadioProps,
	InputNumberProps as AntdInputNumberProps,
	CollapseProps,
	SwitchProps as AntdSwitchProps,
	PopoverProps,
	CheckboxProps as AntdCheckboxProps,
	InputRef,
	ButtonProps as AntdButtonProps,
	DropdownProps,
	AvatarProps as AntdAvatarProps,
	BadgeProps as AntdBadgeProps,
	PopconfirmProps,
	SpaceProps,
	DatePickerProps as AntdDatePickerProps,
} from 'antd'
export type { TextProps } from 'antd/lib/typography/Text'
export type { DefaultOptionType } from 'rc-select/es/Select'
export type { SizeType } from 'antd/lib/config-provider/SizeContext'
export type { TextAreaProps } from 'antd/lib/input/TextArea'
export type { ColProps as AntdColProps } from 'antd/lib/grid/col'
export type { ItemType } from 'antd/es/menu/interface'
export type { BaseSelectRef } from 'rc-select'
export type { RangePickerProps as AntdRangePickerProps } from 'antd/es/date-picker/generatePicker/interface'
export type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
export type { ItemType as AntdBreadcrumbItemProps } from 'antd/es/breadcrumb/Breadcrumb'
export type { DataIndex } from 'rc-table/es/interface'
export type { ColumnType } from 'antd/es/table'
export type { CheckboxChangeEvent } from 'antd/es/checkbox'

export {
	Alert,
	AutoComplete,
	Empty,
	Image,
	Carousel,
	Steps,
	Breadcrumb as AntdBreadcrumb,
	Typography,
	Spin as AntdSpin,
	ConfigProvider,
	App,
	Row,
	Collapse,
	Checkbox as AntdCheckbox,
	TreeSelect,
	Layout as AntdLayout,
	Divider,
	Layout,
	Form,
	Input as AntdInput,
	Col as AntdCol,
	Radio,
	Button as AntdButton,
	Modal,
	Descriptions,
	Popconfirm,
	Dropdown,
	Upload,
	List,
	Select,
	Result,
	Tooltip,
	Popover,
	Menu,
	Segmented,
	Tabs,
	Skeleton,
	InputNumber as AntdInputNumber,
	Drawer,
	Space as AntdSpace,
	DatePicker as AntdDatePicker,
	Switch as AntdSwitch,
	Avatar as AntdAvatar,
	Badge as AntdBadge,
	Card,
	Progress,
} from 'antd'

export { default as ErrorBoundary } from './ErrorBoundary'
export { Text } from './Text'
export type { ITextProps } from './Text/types'
export { Button } from './Button'
export { Input, Group, Search, Password, TextArea } from './Input'
export { RangePicker } from './RangePicker'
export { InputNumber } from './InputNumber'
export { DatePicker } from './DatePicker'
export { Switch } from './Switch'
export { Checkbox } from './Checkbox'
export { Spin } from './Spin'
export { Icon } from './Icon'
export { Col } from './Col'
export { Content } from './Content'
export { BlockTitle } from './BlockTitle'
export { InputMask } from './InputMask'
export { Space } from './Space'
export { FormGenerator } from './FormGenerator'
export { Paper } from './Paper'
export { FormHintField } from './FormHintField'
export { FormLayout } from './FormLayout'
export { default as ShortContent } from './ShortContent'
export { InfoDescription } from './InfoDescription'
export {
	default as AntStaticFunctions,
	message,
	notification,
	modal,
	useNotification,
	confirmDelete,
} from './AntStaticFunctions'

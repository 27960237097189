import classNames from 'classnames'
import React, { useMemo, useState } from 'react'

import { CONTENT_CONTAINER_NAME } from 'common/constants/client'
import { Layout } from 'components'
import Content from 'features/layouts/blocks/Content'
import Footer from 'features/layouts/blocks/Footer'
import Header from 'features/layouts/blocks/Header'

import { MAIN_LAYOUT_DEFAULT_OPTIONS } from './constants'
import { MainLayoutContext } from './context'
import styles from './styles.module.scss'
import { IMainLayoutOptions } from './types'

export const MainLayout = () => {
	const [
		{
			contentClassName,
			className,
			showHeader,
			headerClassName,
		},
		setOptions,
	] = useState<IMainLayoutOptions>(MAIN_LAYOUT_DEFAULT_OPTIONS)

	const contextValue = useMemo(
		() => ({
			initialization: (values = {}) => setOptions({
				...MAIN_LAYOUT_DEFAULT_OPTIONS,
				...values,
			}),
		}),
		[]
	)

	return (
		<Layout className={classNames(styles.root, className)}>
			<MainLayoutContext.Provider value={contextValue}>
				{showHeader && (
					<Layout.Header className={classNames(styles.header, headerClassName)}>
						<Header />
					</Layout.Header>
				)}
				<Layout.Content id={CONTENT_CONTAINER_NAME} className={classNames(styles.content, contentClassName)}>
					<Content />
				</Layout.Content>
				<Layout.Footer className={classNames(styles.footer)}>
					<Footer />
				</Layout.Footer>
			</MainLayoutContext.Provider>
		</Layout>
	)
}
